<template>
  <table>
    <tr style="height: 50px">
      <th>关键问题</th>
      <th>物质和场</th>
      <th>问题的物-场模型</th>
      <th>确定标准解类别</th>
      <th>确定具体的标准解</th>
      <th>解决方案的物-场模型</th>
    </tr>
    <tr v-for="(standardResolve, i) in standardResolves" :key="i">
      <td style="height: 50px; text-align: center; width: 320px">{{ standardResolve.keyProb }}</td>
      <td style="height: 50px; text-align: center; width: 320px">
        <template v-for="(label, j) in obj_field_level_5.find((ele) => `${ele.main}.${ele.sub}.${ele.tail}` == standardResolve.model5)?.labels">
          <v-row :key="j">
            {{ label }}: {{ standardResolve[label] }}
          </v-row>
        </template>
      </td>
      <td style="height: 50px; text-align: center; width: 320px">
        <v-img :height="100" style="width: 320px;" :src="`${publicPath}/objfield/model-${standardResolve.model2}.png`" contain></v-img>
      </td>
      <td style="height: 50px; text-align: center; width: 320px">
        <v-row>
          {{ obj_field_level_3.find(ele => ele.main == standardResolve.model3)?.text }}
        </v-row>
        <v-row>
          {{ obj_field_level_4.find(ele => ele.main == standardResolve.model4.split('.')[0] && ele.sub == standardResolve.model4.split('.')[1])?.text  }}
        </v-row>
      </td>
      <td style="height: 50px; text-align: center; width: 320px">
        {{ obj_field_level_5.find(ele => ele.main == standardResolve.model5.split('.')[0] && ele.sub == standardResolve.model5.split('.')[1] && ele.tail == standardResolve.model5.split('.')[2])?.text  }}
      </td>
      <td style="height: 50px; text-align: center; width: 320px">
        <v-img :height="150" style="width: 320px;" :src="`${publicPath}/objfield/${standardResolve.model5}.png`" contain></v-img>
      </td>
    </tr>
  </table>
</template>
<script>
import {
  obj_field_level_1,
  obj_field_level_2,
  obj_field_level_3,
  obj_field_level_4,
  obj_field_level_5,
} from '@/store/objfield.js'

export default {
  props: {
    standardResolves: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'standardResolves',
    event: 'change',
  },
  data() {
    return {
      obj_field_level_1,
      obj_field_level_2,
      obj_field_level_3,
      obj_field_level_4,
      obj_field_level_5,
      publicPath: process.env.VUE_APP_PUBLIC_PATH
    }
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.standardResolves)
    },
  },
}
</script>
<style scoped></style>
