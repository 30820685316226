var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticStyle:{"text-align":"center"}},[_vm._m(0),_vm._l((_vm.current.selected),function(select,i){return [_vm._l((_vm.relations
          .filter((relation) => !relation.isHide)
          .filter((relation) => relation.from == select.id)),function(relation,j){return [_c('tr',{key:`selected-${i}-relation-${j}`},[(j == 0)?_c('td',{attrs:{"rowspan":_vm.relations.filter((relation) => !relation.isHide).filter((relation) => relation.from == select.id).length}},[_vm._v(" "+_vm._s(_vm.nodes.find((node) => node.id === select.id).text)+" ")]):_vm._e(),_c('td',{staticClass:"Triz_textarea"},[_vm._v(" "+_vm._s(relation.text)+" ")]),_c('td',{staticClass:"Triz_textarea"},[_vm._v(" "+_vm._s(relation.level)+" ")]),_c('td',{staticClass:"Triz_Select-table"},[(relation.rule == '有害功能')?[_vm._v(_vm._s(relation.rule))]:_vm._e(),(relation.rule != '有害功能')?[_c('v-select',{attrs:{"items":_vm.clipRules,"solo":"","flat":"","placeholder":"【请选择】"},on:{"change":_vm.change},model:{value:(relation.rule),callback:function ($$v) {_vm.$set(relation, "rule", $$v)},expression:"relation.rule"}})]:_vm._e()],2),_c('td',{staticClass:"Triz_Select-table"},[(relation.rule == '裁剪规则C')?_c('v-select',{attrs:{"items":_vm.nodes
								.filter((node) => !_vm.current.selected.map((select) => select.id).includes(node.id))
								.filter((node) => node.id != relation.to)
								.map((node) => ({ value: node.id, text: node.text })),"solo":"","flat":"","placeholder":"【请选择】"},on:{"change":_vm.change},model:{value:(relation.new),callback:function ($$v) {_vm.$set(relation, "new", $$v)},expression:"relation.new"}}):_vm._e()],1),_c('td',{staticClass:"Triz_textarea"},[_c('new-triz-textarea',{on:{"input":_vm.change},model:{value:(relation.problem),callback:function ($$v) {_vm.$set(relation, "problem", $$v)},expression:"relation.problem"}})],1)])]})]})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"50px"}},[_c('th',[_vm._v("组件")]),_c('th',[_vm._v("功能")]),_c('th',[_vm._v("功能等级")]),_c('th',[_vm._v("裁剪规则")]),_c('th',[_vm._v("新载体")]),_c('th',[_vm._v("裁剪问题")])])
}]

export { render, staticRenderFns }