<template>
	<div>
		<template v-for="(sub, i) in postion">
			<template v-for="(tail, j) in sub">
				<v-row align="center" justify="center" :key="`matrix-${i}-${j}`">
					<v-col style="position: relative; width: 980px">

						<table cellpadding="0" cellspacing="0">
							<tr :style="`background-color:#cccccc;`">
								<td></td>
								<template v-for="(parameter, i) in parameters">
									<td v-if="i > tail.y - 3 && i < tail.y + 3" :key="`title-col-${i}`">
										{{ parameters[i].text }}
									</td>
								</template>
							</tr>
							<template v-for="(row, i) in matrix">
								<tr v-if="i > tail.x - 3 && i < tail.x + 3" :key="`row-${i}`" style="height: 20px">
									<td :style="`background-color:#cccccc;min-width:150px;`">
										{{ parameters[i].text }}
									</td>
									<template v-for="(col, j) in row">
										<td v-if="j > tail.y - 3 && j < tail.y + 3"
											:style="`background-color:${tail.x === i && tail.y === j? 'pink': tail.x === i || tail.y === j? '#3f81c1': 'white'}`"
											:key="`col-${j}`" style="word-wrap: break-word" class="AltshullerXY">
											{{ col }}
										</td>
									</template>
								</tr>
							</template>
						</table>
					</v-col>
				</v-row>
			</template>
		</template>
	</div>
</template>
<script>
	import {
		matrix,
		principles,
		parameters
	} from '@/store/altshuller'

	export default {
		props: {
			selectedConflict: {
				type: Object,
				default: () => ({}),
			},
			selectedPrinciples: {
				type: Array,
				default: () => [],
			},
		},
		model: {
			prop: 'selectedPrinciples',
			event: 'change',
		},
		data() {
			return {
				matrix,
				principles,
				parameters,
			}
		},
		computed: {
			postion() {
				// 选中格计算
				const cell = []
				for (let i = 0; i < this.selectedConflict.good.length; i++) {
					cell.push([])
					for (let j = 0; j < this.selectedConflict.bad.length; j++) {
						cell[i].push({
							x: this.selectedConflict.good[i] - 1,
							y: this.selectedConflict.bad[j] - 1
						})
					}
				}
				console.log('选中格计算', cell)
				return cell
			},
		},
		watch: {
			postion: {
				handler(val, old) {
					console.log('去重')
					const methods = []
					for (let sub of val) {
						for (let tail of sub) {
							const selected = matrix[tail.x][tail.y]
							if (selected == '+' || selected == '-') {
								continue
							}
							console.log(selected)
							const splited = String(selected).split(',')
							methods.push(...splited)
						}
					}
					this.$emit('change', Array.from(new Set(methods)).map((id) => this.principles.find((ele) => ele
						.value == id)))
				},
				deep: true,
			},
		},
		mounted() {},
		methods: {},
	}
</script>
<style scoped></style>