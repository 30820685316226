<template>
	<table style="text-align: center;">
		<tr style="height: 50px">
			<th>组件</th>
			<th>功能</th>
			<th>功能等级</th>
			<th>裁剪规则</th>
			<th>新载体</th>
			<th>裁剪问题</th>
		</tr>
		<template v-for="(select, i) in current.selected">
			<template v-for="(relation, j) in relations
          .filter((relation) => !relation.isHide)
          .filter((relation) => relation.from == select.id)">
				<tr :key="`selected-${i}-relation-${j}`">
					<td v-if="j == 0"
						:rowspan="relations.filter((relation) => !relation.isHide).filter((relation) => relation.from == select.id).length">
						{{ nodes.find((node) => node.id === select.id).text }}
					</td>

					<td class="Triz_textarea">
						{{ relation.text }}
					</td>
					<td class="Triz_textarea">
						{{ relation.level }}
					</td>
					<td class="Triz_Select-table">
						<template v-if="relation.rule == '有害功能'">{{ relation.rule }}</template>
						<template v-if="relation.rule != '有害功能'">
							<v-select v-model="relation.rule" :items="clipRules" solo flat @change="change"
								placeholder="【请选择】"></v-select>
						</template>
					</td>
					<td class="Triz_Select-table">
						<v-select v-if="relation.rule == '裁剪规则C'" v-model="relation.new" :items=" nodes
								.filter((node) => !current.selected.map((select) => select.id).includes(node.id))
								.filter((node) => node.id != relation.to)
								.map((node) => ({ value: node.id, text: node.text }))" solo flat @change="change"
							placeholder="【请选择】"></v-select>
					</td>
					<td class="Triz_textarea">
						<new-triz-textarea v-model="relation.problem" @input="change"></new-triz-textarea>
					</td>
				</tr>
			</template>
		</template>
	</table>
</template>
<script>
	import {
		functionScores,
		clipRules
	} from '@/store/default'

	export default {
		props: {
			nodes: {
				type: Array,
				default: () => [],
			},
			relations: {
				type: Array,
				default: () => [],
			},
			current: {
				type: Object,
				default: () => ({}),
			},
		},
		data() {
			return {
				functionScores,
				clipRules,
			}
		},
		model: {
			prop: 'current',
			event: 'change',
		},
		computed: {
			locked() {
				return this.$store.state.locked
			},
		},
		mounted() {},
		methods: {
			change() {
				this.$emit('change', this.current)
			},
		},
	}
</script>