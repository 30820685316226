<template>
	<table class="pure-table pure-table-bordered" style="text-align: center;">
		<tr>
			<th></th>
			<th v-for="(_, i) in conflicts" :key="i" :style="`${selected == i ? 'background-color: #1976d247' : ''}`">
				技术矛盾{{ i + 1 }}
			</th>
		</tr>
		<tr>
			<td style="text-align: center; width: 120px; height: 50px">如果</td>
			<td class="Triz_textarea" v-for="(conflict, i) in conflicts" :key="i"
				:style="`${selected == i ? 'background-color: #1976d247' : ''}`">
				<new-triz-textarea v-model="conflict.c_if" @input="change" :placeholder="`${i==0?'【请输入A】':'【请输入-A】'}`"></new-triz-textarea>
			</td>
		</tr>
		<tr>
			<td style="text-align: center; height: 50px">那么</td>
			<td class="Triz_textarea" v-for="(conflict, i) in conflicts" :key="i"
				:style="`${selected == i ? 'background-color: #1976d247' : ''}`">
				<new-triz-textarea v-model="conflict.c_then" @input="change" :placeholder="`${i==0?'【改善了B】':'【改善了-C】'}`"></new-triz-textarea>
			</td>
		</tr>
		<tr>
			<td style="text-align: center; height: 50px">但是</td>
			<td class="Triz_textarea" v-for="(conflict, i) in conflicts" :key="i"
				:style="`${selected == i ? 'background-color: #1976d247' : ''}`">
				<new-triz-textarea v-model="conflict.but" @input="change" :placeholder="`${i==0?'【恶化了C】':'【恶化了-B】'}`"></new-triz-textarea>
			</td>
		</tr>
		<tr>
			<td style="text-align: center; height: 50px">改善的参数</td>
			<td class="Triz_Select-table" v-for="(conflict, i) in conflicts" :key="i"
				:style="`${selected == i ? 'background-color: #1976d247' : ''}`" >
				<new-triz-muti-select v-model="conflict.good" flat
					:items="parameters.map((ele) => ({ value: ele.value, text: `${ele.value}.${ele.text}`,tooltip: ele.tooltip }))" solo
					@change="change">
				</new-triz-muti-select>
			</td>
		</tr>
		<tr>
			<td style="text-align: center; height: 50px">恶化的参数</td>
			<td class="Triz_Select-table" v-for="(conflict, i) in conflicts" :key="i"
				:style="`${selected == i ? 'background-color: #1976d247' : ''}`">
				<new-triz-muti-select v-model="conflict.bad" flat
					:items="parameters.map((ele) => ({ value: ele.value, text: `${ele.value}.${ele.text}`,tooltip: ele.tooltip }))" solo
					@change="change">
				</new-triz-muti-select>
			</td>
		</tr>
	</table>
</template>
<script>
	import {
		parameters
	} from '@/store/altshuller'

	export default {
		props: {
			conflicts: {
				type: Array,
				default: () => [],
			},
			selected: {
				type: Number,
				default: -1,
			},
		},
		model: {
			prop: 'conflicts',
			event: 'change',
		},
		data() {
			return {
				parameters,
			}
		},
		computed: {
		},
		// watch: {
		//   conflicts(value) {
		//     this.$emit('change', value)
		//   },
		// },
		mounted() {},
		methods: {
			addConflict() {
				this.conflicts.push({
					c_if: '',
					c_then: '',
					but: '',
					good: [],
					bad: [],
				})
				this.conflicts.push({
					c_if: '',
					c_then: '',
					but: '',
					good: [],
					bad: [],
				})
				this.change()
			},
			removeConflict(index) {
				this.conflicts.splice(index, 1)
				this.change()
			},
			change() {
				this.$emit('change', this.conflicts)
			},
		},
	}
</script>
<style scoped></style>