<template>
  <table>
    <tr style="height: 80px">
      <th>序号</th>
      <th>缺陷</th>
      <th>详细</th>
    </tr>
    <template
      v-for="(relation, i) in relations
        .filter((relation) => !relation.isHide)
        .filter(
          (relation) =>
            relation.lineShape == lineShapes.bad ||
            relation.lineShape == lineShapes.unsatisfy ||
            relation.lineShape == lineShapes.over
        )"
    >
      <tr style="text-align: center" :key="i">
        <td>
          {{ i + 1 }}
        </td>
        <td class="Triz_textarea">
          {{
            `${nodes.find((node) => node.id == relation.from).text}${relation.text}${
              nodes.find((node) => node.id == relation.to).text
            }功能${lineShapesText[relation.lineShape]}`
          }}
        </td>
        <td class="Triz_textarea">
		  <v-textarea  rows="1" placeholder="【请输入】"
		  	@input="change" v-model="relation.issue"></v-textarea>
        </td>
      </tr>
    </template>
  </table>
</template>
<script>
import { lineShapes, lineShapesText } from '@/store/default'

export default {
  props: {
    nodes: {
      type: Array,
      default: () => [],
    },
    relations: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'relations',
    event: 'change',
  },
  data() {
    return {
      lineShapesText,
      lineShapes,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.relations)
    },
  },
}
</script>
<style scoped></style>
