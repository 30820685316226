var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',[_vm._m(0),_vm._l((_vm.relations
        .filter((relation) => !relation.isHide)
        .filter(
          (relation) =>
            relation.lineShape == _vm.lineShapes.bad ||
            relation.lineShape == _vm.lineShapes.unsatisfy ||
            relation.lineShape == _vm.lineShapes.over
        )),function(relation,i){return [_c('tr',{key:i,staticStyle:{"text-align":"center"}},[_c('td',[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('td',{staticClass:"Triz_textarea"},[_vm._v(" "+_vm._s(`${_vm.nodes.find((node) => node.id == relation.from).text}${relation.text}${ _vm.nodes.find((node) => node.id == relation.to).text }功能${_vm.lineShapesText[relation.lineShape]}`)+" ")]),_c('td',{staticClass:"Triz_textarea"},[_c('v-textarea',{attrs:{"rows":"1","placeholder":"【请输入】"},on:{"input":_vm.change},model:{value:(relation.issue),callback:function ($$v) {_vm.$set(relation, "issue", $$v)},expression:"relation.issue"}})],1)])]})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"80px"}},[_c('th',[_vm._v("序号")]),_c('th',[_vm._v("缺陷")]),_c('th',[_vm._v("详细")])])
}]

export { render, staticRenderFns }