<template>
	<table style="text-align: center">
		<tr style="height: 50px">
			<th>序号</th>
			<th>关键缺点</th>
			<th>关键问题</th>
			<th>矛盾描述</th>
		</tr>
		<tr v-for="(reason, i) in list" :key="i">
			<td>
				{{ reason.prefix }}
			</td>
			<td class="Triz_textarea">
				<v-textarea  variant="filled" auto-grow rows="2" row-height="20" @input="change"
					v-model="reason.label"></v-textarea>
			</td>
			<td class="Triz_textarea">
				<v-textarea  variant="filled" auto-grow rows="2" row-height="20" placeholder="【请输入关键缺点的反向】" @input="change"
					v-model="reason.problem"></v-textarea>
			</td>
			<td class="Triz_textarea">
				<v-textarea  variant="filled" auto-grow rows="2" row-height="20" placeholder="【请输入】" @input="change" v-model="reason.desc"></v-textarea>
			</td>
		</tr>
	</table>
</template>
<script>
	export default {
		props: {
			list: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {}
		},
		computed: {
			locked() {
				return this.$store.state.locked
			},
		},
		mounted() {},
		methods: {
			change() {
				this.$emit('change', this.list)
			},
		},
	}
</script>